import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePathBuilder, CoBrandingButton, customStyles } from 'ciam-self-service-shared';
import { useHistory } from 'react-router-dom';
import useUniversalLink from '../../../hooks/useUniversalLink';

function SigninButton(props) {
  const { children, ...otherProps } = props;
  const { isVerified } = props;
  const history = useHistory();
  const { locale } = useIntl();
  const pathBuilder = usePathBuilder();
  const universalLink = useUniversalLink();

  const handleClick = useCallback(() => {
    if (universalLink) {
      window.location.href = universalLink;
    } else {
      history.push(
        pathBuilder('/signin', true, {
          locale,
          searchParams: { ui_locales: locale },
        }),
        { isVerified },
      );
    }
  }, [universalLink, pathBuilder, isVerified, history, locale]);

  return (
    <CoBrandingButton id="sign-in-button" onClick={handleClick} customStyle={customStyles.button} {...otherProps}>
      {children || <FormattedMessage id="common.signin" />}
    </CoBrandingButton>
  );
}

SigninButton.propTypes = {
  isVerified: PropTypes.bool,
  children: PropTypes.node,
};

SigninButton.defaultProps = {
  isVerified: false,
  children: null,
};

export default SigninButton;
